import * as Popover from "@radix-ui/react-popover";
import { ReactNode } from "react";

export interface SelectItem {
  value: string;
  label: string;
}
interface Props {
  trigger: ReactNode;
  content: ReactNode;
}

const MyPopover = ({ trigger, content }: Props) => (
  <Popover.Root>
    <Popover.Trigger asChild>{trigger}</Popover.Trigger>
    <Popover.Portal>
      <Popover.Content
        side="bottom"
        style={{ outline: "none", borderRadius: "10px" }}
      >
        {content}
      </Popover.Content>
    </Popover.Portal>
  </Popover.Root>
);

export default MyPopover;
