import { Col, Image, Row } from "@/styles/layout";
import { getCurrentTheme } from "@/styles/theme";
import { Heading } from "@/styles/typography";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";

import { IconType } from "react-icons";
import { motion } from "framer-motion";
import { Route, appRoutes } from "@/config/navigation";
import { useAccount } from "@/hooks/useAccount";
import { useAuth } from "@/providers/auth.provider";
import { getMediaCdnUrl } from "@/lib/cdn";

interface ItemProps {
  name: string;
  href: string;
  Icon: IconType;
  childrenRoutes?: Route[];
}

const Item = ({ name, href, Icon, childrenRoutes }: ItemProps) => {
  const router = useRouter();
  const active = router.pathname === href;

  const color = active
    ? getCurrentTheme().colors.secondary.accent
    : getCurrentTheme().colors.primary.bgBase;

  const containerVariants = {
    open: {
      height: "auto",
      transition: {
        staggerChildren: 0.1,
        ease: "easeInOut",
        duration: 0.3,
      },
    },
    closed: {
      height: "auto",
      transition: {
        staggerChildren: 0.1,
        ease: "easeInOut",
        duration: 0.3,
      },
    },
  };

  const childVariants = {
    open: { opacity: 1, height: "auto", display: "initial" },
    closed: { opacity: 0, height: 0, display: "none" },
  };

  const hasChildren = useMemo(
    () => childrenRoutes && childrenRoutes.filter((c) => !c.hidden).length > 0,
    [childrenRoutes]
  );

  const hasActiveChildren =
    hasChildren && childrenRoutes?.some((r) => router.pathname === r.path);

  const expand = useMemo(
    () => hasActiveChildren || (hasChildren && active),
    [active, hasActiveChildren, hasChildren]
  );

  return (
    <motion.div
      variants={containerVariants}
      initial="closed"
      animate={expand ? "open" : "closed"}
    >
      <Col>
        <Row alignItems={"center"} p="8px" borderRadius="4px">
          <Icon size={18} color={color} />
          <Col ml="12px">
            <Link href={href} style={{ textDecoration: "none" }}>
              <Heading
                fontSize="14px"
                color={color}
                fontWeight={active ? "600" : "300"}
              >
                {name}
              </Heading>
            </Link>
          </Col>
        </Row>
        {hasChildren && (
          <motion.div
            variants={childVariants}
            initial="closed"
            animate={expand ? "open" : "closed"}
          >
            <Col pl="16px">
              {(childrenRoutes ?? []).map((r) => (
                <Item
                  key={r.name}
                  name={r.name}
                  href={r.path}
                  Icon={r.icon}
                  childrenRoutes={r.children}
                />
              ))}
            </Col>
          </motion.div>
        )}
      </Col>
    </motion.div>
  );
};

const Footer = () => {
  const { account } = useAccount();

  const logoUrl: string | undefined = useMemo(() => {
    return account?.branding?.logoUrl;
  }, [account]);

  const name: string = useMemo(() => {
    return account?.name ?? "";
  }, [account]);

  const logo = useMemo(
    () => (!account ? undefined : getMediaCdnUrl(account.id, "logo", logoUrl)),
    [account, logoUrl]
  );

  return (
    <Col
      p="16px"
      alignItems="stretch"
      justifyContent={"center"}
      borderTopStyle="solid"
      borderColor="primary.borderHover"
    >
      {logo && (
        <Row justifyContent={"center"} mb="2">
          <Image src={logo} width="56" height="auto" alt="GoGive logo" />
        </Row>
      )}
      <Heading
        fontSize="14px"
        fontWeight="500"
        color="primary.bgBase"
        textAlign="center"
      >
        {name}
      </Heading>
    </Col>
  );
};

const SideNav = () => {
  const { user } = useAuth();
  const isAdmin = useMemo(
    () => (!user ? false : ["SUPER_ADMIN", "ADMIN"].includes(user.role)),
    [user]
  );

  return (
    <Col
      bg="primary.accent"
      minWidth="240px"
      width="240px"
      alignItems={"stretch"}
    >
      <Link href="/" style={{ textDecoration: "none" }}>
        <Row
          height="77px"
          mx="16px"
          alignItems="center"
          justifyContent={"flex-start"}
          borderBottomStyle="solid"
          borderColor="primary.borderHover"
        >
          <Image
            src="/logo-white-hand.png"
            width="56"
            height="auto"
            alt="GoGive logo"
          />
          <Heading
            ml="16px"
            fontSize="24px"
            fontWeight="500"
            color="primary.bgBase"
          >
            GoGive
          </Heading>
        </Row>
      </Link>

      <Col px="16px" flexGrow="1">
        <Col py="32px">
          {appRoutes.map((group) => (
            <Col mb="32px" key={group.label}>
              <Heading
                fontSize="12px"
                fontWeight="500"
                color="primary.elementActive"
                letterSpacing={1}
                mb="8px"
              >
                {group.label.toUpperCase()}
              </Heading>
              <Col alignItems={"stretch"} px="0px">
                {group.routes
                  .filter(
                    (r: Route) => !r.adminOnly || (r.adminOnly && isAdmin)
                  )
                  .map((r: any) => (
                    <Item
                      key={r.name}
                      name={r.name}
                      href={r.path}
                      Icon={r.icon}
                      childrenRoutes={r.children}
                    />
                  ))}
              </Col>
            </Col>
          ))}
        </Col>
      </Col>
      <Footer />
    </Col>
  );
};

export default SideNav;
