import { useAuth } from "@/providers/auth.provider";
import { Col } from "@/styles/layout";
import { Heading } from "@/styles/typography";
import { useMemo } from "react";

const Avatar = () => {
  const { user } = useAuth();

  const initial = useMemo(() => {
    if (user) {
      return [user.firstName, user.lastName].map((s) =>
        s.charAt(0).toUpperCase()
      );
    }
  }, [user]);

  return (
    <Col
      justifyContent={"center"}
      alignItems={"center"}
      p="3"
      bg="primary.element"
      borderRadius="50%"
      boxShadow={"l"}
    >
      <Heading fontSize="16px" fontWeight={600} color="primary.accent">
        {initial}
      </Heading>
    </Col>
  );
};

export default Avatar;
