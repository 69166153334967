import { Col, Div, Row } from "@/styles/layout";
import Avatar from "./Avatar";
import MyPopover from "@/components/Popover";
import ProfileMenu from "./ProfileMenu";
import { useMemo } from "react";
import { Body } from "@/styles/typography";
import { useNotifications, Notification } from "@/hooks/NotificationsProvider";
import { RxCross2 } from "react-icons/rx";
import { AnimatePresence, motion } from "framer-motion";

const InfoBar = () => {
  const { notifications } = useNotifications();
  return (
    <Row
      bg="primary.bgBase"
      height="77px"
      borderColor="primary.borderSubtle"
      borderBottomStyle="solid"
      alignItems={"center"}
      justifyContent={"space-between"}
      px="24px"
    >
      <AnimatePresence>
        <Row
          justifyContent={"center"}
          flexGrow="1"
          maxWidth="75vw"
          overflowX="auto"
        >
          {notifications.map((notification) => (
            <NotificationToast
              key={notification.id}
              notification={notification}
            />
          ))}
        </Row>
      </AnimatePresence>

      <Row>
        <MyPopover
          trigger={
            <Div>
              <Avatar />
            </Div>
          }
          content={<ProfileMenu />}
        />
      </Row>
    </Row>
  );
};

export default InfoBar;

const NotificationToast = ({
  notification,
}: {
  notification: Notification;
}) => {
  const { removeNotification } = useNotifications();
  const { bg, color } = useMemo(() => {
    if (notification.mode === "critical") {
      return { bg: "primary.accent", color: "primary.bgBase" };
    } else if (notification.mode === "warning") {
      return { bg: "warning.accent", color: "warning.bgBase" };
    } else {
      return { bg: "primary.element", color: "primary.textLow" };
    }
  }, [notification]);

  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0 }}
      transition={{ type: "spring", stiffness: 200, mass: 0.8, damping: 16 }}
    >
      <Row bg={bg} borderRadius="4px" mx="8px" alignItems="center" py="8px">
        <Row px="16px">
          <Body
            color={color}
            fontSize="14px"
            fontWeight="500"
            style={{ whiteSpace: "nowrap" }}
          >
            {notification.payload}
          </Body>
        </Row>
        {notification.dismissable && (
          <Col
            color={color}
            px="16px"
            justifyContent={"center"}
            style={{ cursor: "pointer" }}
            onClick={() => removeNotification(notification.id)}
          >
            <RxCross2 size={18} />
          </Col>
        )}
      </Row>
    </motion.div>
  );
};
