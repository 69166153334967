import themeGet from "@styled-system/theme-get";
import styled from "styled-components";
import {
  position,
  flexbox,
  layout,
  space,
  color,
  border,
  typography,
  shadow,
  grid,
} from "styled-system";
import { motion } from "framer-motion";
import { StyledComponentProps } from "styled-components";
import {
  SpaceProps,
  LayoutProps,
  FlexboxProps,
  PositionProps,
  BorderProps,
  TypographyProps,
  ColorProps,
  ShadowProps,
  GridProps,
} from "styled-system";
import { HTMLMotionProps } from "framer-motion";

export type DivProps = StyledComponentProps<"div", any, {}, never> &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  BorderProps &
  TypographyProps &
  ColorProps &
  ShadowProps &
  GridProps;

export type InputProps = StyledComponentProps<"input", any, {}, never> &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  BorderProps &
  TypographyProps &
  ColorProps &
  ShadowProps &
  GridProps &
  HTMLMotionProps<"input">;

export type TextAreaProps = StyledComponentProps<"textarea", any, {}, never> &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  BorderProps &
  TypographyProps &
  ColorProps &
  ShadowProps &
  GridProps &
  HTMLMotionProps<"textarea">;

export type ButtonProps = StyledComponentProps<"button", any, {}, never> &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  BorderProps &
  TypographyProps &
  ColorProps &
  ShadowProps &
  GridProps;

export type TableProps = StyledComponentProps<"table", any, {}, never> &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  BorderProps &
  TypographyProps &
  ColorProps &
  ShadowProps;

export type THeaderProps = StyledComponentProps<"thead", any, {}, never> &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  BorderProps &
  TypographyProps &
  ColorProps &
  ShadowProps;

export type TRProps = StyledComponentProps<"tr", any, {}, never> &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  BorderProps &
  TypographyProps &
  ColorProps &
  ShadowProps;
export type THProps = StyledComponentProps<"th", any, {}, never> &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  BorderProps &
  TypographyProps &
  ColorProps &
  ShadowProps;

export type TBodyProps = StyledComponentProps<"tbody", any, {}, never> &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  BorderProps &
  TypographyProps &
  ColorProps &
  ShadowProps;

export type TDProps = StyledComponentProps<"td", any, {}, never> &
  SpaceProps &
  LayoutProps &
  FlexboxProps &
  PositionProps &
  BorderProps &
  TypographyProps &
  ColorProps &
  ShadowProps;

export const Div = styled.div<DivProps>`
  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${border}
  ${typography}
  ${shadow}  
  ${grid}
`;

export const Button = styled(motion.button)<ButtonProps>`
  outline: none;
  border: none;

  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${position}
  ${border}
  ${typography}
  ${shadow}
`;

export const Col = styled.div<DivProps>`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${color}
  ${space}
  ${layout}
  ${flexbox}
  ${border}
  ${position}
  ${typography}
  ${shadow}
  ${grid}
`;

export const Row = styled.div<DivProps>`
  display: flex;
  ${color}
  ${space}
  ${layout}
  ${flexbox};
  ${border}
  ${position}
  ${typography}
  ${shadow}
  ${grid}
`;

type StyledImageProps = SpaceProps &
  LayoutProps &
  FlexboxProps &
  BorderProps &
  PositionProps &
  React.ImgHTMLAttributes<HTMLImageElement>;

export const Image = styled.img<StyledImageProps>`
  ${space}
  ${layout}
  ${flexbox}
  ${border}
  ${position}
`;

export const StyledInput = styled(motion.input)<InputProps>`
  outline: none;
  appearance: none;

  height: 50px;
  font-size: 16px;
  font-weight: 600;
  border-radius: ${themeGet("radii.0")}px;
  padding-left: 8px;

  background-color: transparent;
  box-sizing: border-box;

  ${layout}
  ${space}
  ${color}
  ${border}
  ${typography}


  ::placeholder {
    color: ${({ placeholderColor }: any) => `${placeholderColor}`};
    font-weight: 300;
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0 30px red inset !important;
  }
  input:-webkit-autofill:hover {
    box-shadow: 0 0 0 30px red inset !important;
  }
  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 30px red inset !important;
  }
`;

export const Hoverable = styled.div<DivProps>`
  ${space}
  ${color}
  ${layout}
  ${flexbox}
  ${border}
  ${position}
  ${shadow}
  ${grid}
 
  &:hover {
    cursor: pointer;
  }
`;

export const Table = styled.table<TableProps>`
  ${color}
  ${space}
  ${layout}
  ${flexbox};
  ${border}
  ${position}
  ${typography}
  ${shadow}
`;

export const THead = styled.thead<THeaderProps>`
  ${color}
  ${space}
  ${layout}
  ${flexbox};
  ${border}
  ${position}
  ${typography}
  ${shadow}
`;

export const Tr = styled.tr<TRProps>`
  ${color}
  ${space}
  ${layout}
  ${flexbox};
  ${border}
  ${position}
  ${typography}
  ${shadow}
`;

export const Th = styled.th<THProps>`
  ${color}
  ${space}
  ${layout}
  ${flexbox};
  ${border}
  ${position}
  ${typography}
  ${shadow}
`;

export const TBody = styled.tbody<TBodyProps>`
  ${color}
  ${space}
  ${layout}
  ${flexbox};
  ${border}
  ${position}
  ${typography}
  ${shadow}
`;

export const Td = styled.td<TDProps>`
  ${color}
  ${space}
  ${layout}
  ${flexbox};
  ${border}
  ${position}
  ${typography}
  ${shadow}
`;

export const StyledTextArea2 = styled.textarea`
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.1em;

  outline: none;
  background-color: ${themeGet("colors.bgBase")} !important;
  border: 1px solid ${themeGet("colors.border")};

  color: ${themeGet("colors.text.main.high")} !important;

  ${layout}
  ${space}
  ${color}
  ${border}
  ${typography}


  &:hover {
    background-color: ${themeGet("colors.element")} !important;
    border: 1px solid ${themeGet("colors.borderHover")};
  }

  &:focus {
    background-color: ${themeGet("colors.bgSubtle")} !important;
    border: 1px solid ${themeGet("colors.accent")};
  }

  &:autofill {
    background-color: ${themeGet("colors.element")};
  }
`;

export const StyledTextArea = styled(motion.textarea)<TextAreaProps>`
  outline: none;
  appearance: none;

  height: 50px;
  font-size: 16px;
  font-weight: 600;
  border-radius: ${themeGet("radii.0")}px;
  padding-left: 8px;

  background-color: transparent !important;
  box-sizing: border-box;

  letter-spacing: 0.05em;
  line-height: 1.5em;

  ${layout}
  ${space}
  ${color}
  ${border}
  ${typography}


  ::placeholder {
    color: ${({ placeholderColor }: any) => `${placeholderColor}`};
  }

  input:-webkit-autofill {
    box-shadow: 0 0 0 30px red inset !important;
  }
  input:-webkit-autofill:hover {
    box-shadow: 0 0 0 30px red inset !important;
  }
  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 30px red inset !important;
  }
`;
