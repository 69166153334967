import { Col, Div, Row } from "@/styles/layout";
import { Body, Heading } from "@/styles/typography";
import Head from "next/head";
import { ReactNode } from "react";
import SideNav from "./SideNav";
import InfoBar from "./InfoBar";
import { IconType } from "react-icons";
import { getCurrentTheme } from "@/styles/theme";
import { AnimatePresence, motion } from "framer-motion";
import { usePageInfo } from "@/hooks/usePageInfo";
import { TbFaceIdError } from "react-icons/tb";
import Loader from "@/components/Loader";
import { useRouter } from "next/router";
import { useAuth } from "@/providers/auth.provider";

interface PageProps {
  title: string;
  description?: string;
  header?: string;
  Icon?: IconType;
  children: ReactNode;
  headerChildren?: ReactNode;
}
const Page = ({
  title,
  description,
  header,
  Icon,
  headerChildren,
  children,
}: PageProps) => {
  const { authenticated } = useAuth();
  const router = useRouter();

  const pageInfo = usePageInfo();
  const notAuthorized = false;
  // const notAuthorized =
  //   (sessionData as any)?.role === "MEMBER" && pageInfo.adminOnly;

  // useEffect(() => {

  //   if (
  //     process.env.NEXT_PUBLIC_STAGE === "prod"
  //   ) {
  //     LogRocket.identify(sessionData.data.user.email, {
  //       charity: (sessionData.data as any)?.account.name,
  //       name: sessionData.data.user.name!,
  //       email: sessionData.data.user.email,
  //     });
  //   }
  // }, [sessionData]);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main>
        <Row width="100vw" minHeight="100vh" maxHeight="100vh" bg="gray.2">
          <SideNav />
          {authenticated && (
            <Col alignItems="stretch" flexGrow="1">
              <InfoBar />
              <Col alignItems="center" flexGrow="1" p="24px" overflow="auto">
                <Col maxWidth="1200px" width="100%" flexGrow="1">
                  <AnimatePresence>
                    <motion.div
                      initial={{ opacity: 0, x: -10 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0 }}
                    >
                      {header && (
                        <Row
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          mb="44px"
                        >
                          <Row alignItems={"center"}>
                            {!!Icon && (
                              <Div mr="3">
                                <Icon
                                  size={44}
                                  color={
                                    getCurrentTheme().colors.primary.textHigh
                                  }
                                />
                              </Div>
                            )}
                            <Heading fontSize="24px" fontWeight="600">
                              {header}
                            </Heading>
                          </Row>
                          {!notAuthorized && (
                            <Row alignItems="center">{headerChildren}</Row>
                          )}
                        </Row>
                      )}
                    </motion.div>
                  </AnimatePresence>
                  {notAuthorized ? (
                    <Col alignItems="center">
                      <Div color="primary.textHigh" mb="16px">
                        <TbFaceIdError size={72} />
                      </Div>
                      <Body
                        fontSize="16px"
                        fontWeight="400"
                        color="primary.textHigh"
                        textAlign={"center"}
                      >
                        You are not authorized to access this page.
                        <div>Contact your administrator</div>
                      </Body>
                    </Col>
                  ) : (
                    <>{children}</>
                  )}
                </Col>
              </Col>
            </Col>
          )}
          {!authenticated && (
            <>
              <Col
                position="fixed"
                backgroundColor={"overlay.10"}
                top="0"
                width="100vw"
                height="100vh"
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Div color="primary.accent">
                  <Loader size={77} />
                </Div>
              </Col>
            </>
          )}
        </Row>
      </main>
    </>
  );
};

export default Page;
