import { useMemo } from "react";
import { fetcher } from "@/lib/apiFetch";
import { Font } from "@/pages/api/google-fonts";
import { useRouter } from "next/router";
import useSWR from "swr";
import { findRouteByPath } from "@/config/navigation";

export function usePageInfo() {
  const router = useRouter();
  const route = useMemo(
    () => findRouteByPath(router.pathname),
    [router.pathname]
  );

  return { ...route };
}
