import React, { CSSProperties, ReactNode, useState } from "react";
import { motion } from "framer-motion";

interface Props {
  children: ReactNode;
  style?: CSSProperties;
}

const Hoverable = ({ children, style }: Props) => {
  const [hover, setHover] = useState(false);
  const [focus, setFocus] = useState(false);

  return (
    <motion.div
      initial={{ transform: "scale(0.95)" }}
      animate={{ transform: `scale(${hover ? 0.98 : 1})` }}
      transition={{ type: "spring" }}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setFocus(false)}
      onBlur={() => setFocus(false)}
      style={{ ...style }}
    >
      {children}
    </motion.div>
  );
};

export default Hoverable;
