import { getAccessToken } from "axios-jwt";
import Cookies from "js-cookie";

interface RequestParam {
  method: "GET" | "POST" | "DELETE";
  url: string;
  queryParam?: any | undefined;
  body?: any | undefined;
  authRequired?: boolean;
  jwt?: string | undefined;
  jwtPrefix?: string;
}

export interface APIResponse<T> {
  data: T;
}

export const apiFetch = async <T, U = T>({
  method,
  url,
  queryParam,
  body,
  authRequired = true,
}: RequestParam): Promise<U> => {
  /*
    Construct full url with query parameters
  */
  let fullUrl = `${url}`;

  const params = new URLSearchParams();
  if (queryParam && Object.keys(queryParam).length > 0) {
    Object.keys(queryParam).forEach((k) => {
      if (typeof queryParam[k] !== "undefined") {
        params.append(k, queryParam[k]);
      }
    });
    fullUrl += `?${params.toString()}`;
  }
  const token = await getAccessToken();
  console.log(token);

  const fetchOptions: any = {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `JWT ${token}`,
    },
  };

  if (body) {
    try {
      fetchOptions.body = JSON.stringify(body);
    } catch (e) {
      console.log(body);
      console.warn(e);
    }
  }

  const response = await fetch(fullUrl, fetchOptions);
  if (response.ok) {
    const contentType = response.headers.get("Content-Type");
    console.log(contentType);
    if (contentType?.includes("csv") || contentType?.includes("pdf")) {
      const base64Content = await response.text();
      const binaryContent = Buffer.from(base64Content, "base64");

      return binaryContent as U;
    } else {
      const data = (await response.json()) as T;
      return data as unknown as U;
    }
  }

  const msg = await response.text();
  if (msg) {
    throw new Error(msg);
  }
  throw new Error(
    `[${response.status}] ${fullUrl.toString()} - ${response.statusText}`
  );
};

export const fetcher = <T>([url, queryParam, authRequired]: any[]): Promise<
  APIResponse<T>
> => apiFetch({ method: "GET", url, queryParam });
