import React from "react";
import { motion } from "framer-motion";
import { AiOutlineLoading } from "react-icons/ai";

const Loader = ({ size = 20 }: { size: number }) => {
  return (
    <div className="flex flex-col items-center justify-center ">
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ ease: "linear", duration: 0.5, repeat: Infinity }}
      >
        <AiOutlineLoading size={size} />
      </motion.div>{" "}
    </div>
  );
};

export default Loader;
