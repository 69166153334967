export type VisualSize = "small" | "medium" | "large";

export const getMediaCdnUrl = (
  accountId: string,
  mediaType: "logo" | "banner",
  mediaUrl?: string,
  size: VisualSize = "medium"
) => {
  if (!mediaUrl) return null;
  if (mediaUrl.includes("https")) return mediaUrl;
  else {
    return `https://d22y9kx9wskw4z.cloudfront.net/account/${accountId}/${mediaType}/${size}/${mediaUrl}`;
  }
  return null;
};
