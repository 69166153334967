import { Account } from "@/types/models/Account";
import { routes } from "@/services/account.service";
import useSWR from "swr";
import { fetcherV2 } from "@/services/api";

export function useAccount() {
  const { data, error, mutate } = useSWR([routes.get, {}], fetcherV2<Account>, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: true,
    suspense: false,
    shouldRetryOnError: false,
  });

  return {
    account: data,
    refresh: mutate,
    isLoading: !error && !data,
    isError: error,
  };
}
