import { APIResponse, apiFetch } from "@/lib/apiFetch";
import { API_V2 } from "@/config/api";
import Stripe from "stripe";
import { Account } from "@/types/models/Account";
import apiRequest from "./api";

export const routes = {
  get: `${API_V2}/accounts/me`,
  getStats: `${API_V2}/accounts/stats`,
  getStripeAccount: `${API_V2}/accounts/payment-processor`,
  getStripeConnectLink: `${API_V2}/accounts/payment-processor/connect`,
  getStripePaymentSetup: `${API_V2}/accounts/stripe/payment_setup`,
  getStripePaymentMethod: `${API_V2}/accounts/stripe/method`,
  deleteStripePaymentMethod: `${API_V2}/accounts/stripe/method`,
  refreshStripePaymentMethod: `${API_V2}/accounts/stripe/method/refresh`,
  create: `${API_V2}/accounts`,
  update: `${API_V2}/accounts/me`,
  check: `${API_V2}/accounts/check`,
  uploadLogo: `${API_V2}/accounts/me/logo`,
  uploadBanner: `${API_V2}/accounts/me/banner`,
};

export interface AccountStats {
  raised: number;
  donors: number;
  donations: number;
  roundupPlans: number;
  recurringPlans: number;
}

export interface StripePaymentSetupResponse {
  secret: string;
}

export interface PaymentMethodResponse {
  method: Stripe.PaymentMethod | undefined;
}

export const deletePaymentMethod = () =>
  apiFetch<APIResponse<null>>({
    method: "DELETE",
    url: routes.deleteStripePaymentMethod,
    authRequired: true,
  });

export const refreshDefaultPaymentMethod = () =>
  apiFetch<APIResponse<null>>({
    method: "POST",
    url: routes.refreshStripePaymentMethod,
    authRequired: true,
  });

export const updateAccount = (input: Pick<Account, "defaultRoundupMode">) =>
  apiFetch<APIResponse<Account>>({
    method: "POST",
    url: routes.update,
    authRequired: true,
    body: input,
  });

export interface CreateAccountRequest {
  name: string;
  email: string;
  abn: string;
  website: string;
  dgr: boolean;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    position: string;
  };
}

export const createAccount = (input: CreateAccountRequest) =>
  apiRequest<Account>(routes.create, {
    method: "POST",
    data: input,
  });

export interface CheckAccountRequest {
  name: string;
  email: string;
  abn: string;
}

export const checkAccount = (input: CheckAccountRequest) =>
  apiRequest<null>(routes.check, {
    method: "POST",
    data: input,
  });

export const uploadLogo = (data: FormData) =>
  apiRequest<void>(routes.uploadLogo, {
    method: "POST",
    data,
  });

export const uploadBanner = (data: FormData) =>
  apiRequest<void>(routes.uploadBanner, {
    method: "POST",
    data,
  });
